import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class LabelList extends PagedQueryResult<Label> {
	constructor(copy?: Partial<LabelList>) {
		super();
		Object.assign(this, copy);
	}
}

export class LabelFullList extends PagedQueryResult<LabelFull> {
	constructor(copy?: Partial<LabelFullList>) {
		super();
		Object.assign(this, copy);
	}
}

export class LabelQueryParams extends PagedQueryParams {}

export type Basic = {
    label: string
    id_tag: number
};

export type Label = Basic & {
	id: number
    id_type_label: number
};

export type LabelFull = Basic & {
	id: number
	name: string
	comment: string
};

export type AddLabelCommand = Basic & {
    id_type_label: number
};

export type EditLabelCommand = Basic & {
	id: number
    id_type_label: number
};

export interface LabelService {
	list(params: LabelQueryParams, id_tag: number): Promise<LabelList>;
	listFull(params: LabelQueryParams, id_tag: number): Promise<LabelFullList>;
	add(params: AddLabelCommand): Promise<Label>;
	edit(params: EditLabelCommand): Promise<Label>;
	remove(id: number): Promise<null>;
}
