
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import AppHeader from "@/components/header/index.vue";
import AppNavigation from "@/components/navigation/index.vue";

export default defineComponent({
	emits: ["menu-changed"],
	components: { AppHeader, AppNavigation },
	setup() {
		const {state, commit} = useStore();

		const navigationWidth = ref(300);

		const isLaptop = ref(true);
		isLaptop.value = window.innerWidth >= 960;
		window.addEventListener('resize', () => isLaptop.value = window.innerWidth >= 960);

		const isMenuOpened = ref(false);

		const isNavigationRight = ref(0);
		watch(() => state.globals.detailsTypeResource, () => isNavigationRight.value = (state.globals.detailsTypeResource || state.globals.detailsResource) ? navigationWidth.value : 0);
		watch(() => state.globals.detailsResource, () => isNavigationRight.value = (state.globals.detailsTypeResource || state.globals.detailsResource) ? navigationWidth.value : 0);

		return { state, commit, navigationWidth, isLaptop, isMenuOpened, isNavigationRight };
	},
});
