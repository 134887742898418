import {AxiosApiClient} from "@/api/axiosApiClient";
import {Searches, Maps} from "@/models";
import {list} from "@/api/list";

export class SearchApi extends AxiosApiClient implements Searches.SearchService {
	private readonly baseUrl = '/search/';

	public async listTables(): Promise<Searches.TableName[]> {
		const items = await this.get(this.baseUrl + 'list_tables');

		return items.map(item => ({
			...item,
			id: item.table_name
		}))
	}

	public async listFields(table_name: string): Promise<Searches.ColumnName[]> {
		const items = await this.get(this.baseUrl + 'list_fields/' + table_name);

		return items.map(item => ({
			...item,
			id: item.column_name
		}))
	}

	public async search(params: Searches.SearchQueryParams): Promise<Searches.SearchList> {
		const items = await this.post(this.baseUrl, params);

		const filterIdTypeRes = params.id_type_res
			? items.filter(item => item.id_type_res === params.id_type_res)
			: items

		return list<Searches.Search, Searches.SearchQueryParams>(filterIdTypeRes, params);
	}

	public async jsonKeys(id_enterprise: number, type_res: number): Promise<Searches.jsonKey[]> {
		const items = await this.get(this.baseUrl + 'json_keys/' + id_enterprise + '/' + type_res);

		return items.map(item => ({
			id: item.keys,
			name: item.keys
		}))
	}

	public async resources(id_enterprise: number, type_res: number, key: string, value: string, cancelToken: any): Promise<Maps.Map[]> {
		return await this.get(this.baseUrl + 'resources/' + id_enterprise + '/' + type_res + '/' + key + '/' + value, {}, cancelToken);
	}
}