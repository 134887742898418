import {AxiosApiClient} from "@/api/axiosApiClient";
import {Groups} from "@/models";
import {list} from "@/api/list";

export class GroupApi extends AxiosApiClient implements Groups.GroupService {
	private readonly baseUrl = '/group/';

	public async list(params: Groups.GroupQueryParams = {}): Promise<Groups.GroupList> {
		const items = await this.get(this.baseUrl);
		return list<Groups.Group, Groups.GroupQueryParams>(items, params);
	}

	public async add(params: Groups.AddGroupCommand): Promise<Groups.Group> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: Groups.EditGroupCommand): Promise<Groups.Group> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(id_group: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + id_group);
	}
}