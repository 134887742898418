
import {defineComponent, ref} from 'vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
	name: 'AppFileUpload',
	emits: ["update-file"],
	props: {
		avatar: {
			type: String,
			default: ""
		}
	},
	setup(_, {emit}) {
		const { t } = useI18n();

		const image = ref("");

		const onFileChange = (event: any) => {
			const file = event.target.files[0];
			if (!file || !file.type.match('image.*')) {
				return false
			}
			const reader = new FileReader();
			reader.onload = (e: any) => {
				image.value = e.target.result;
				emit("update-file", image.value);
			}
			reader.readAsDataURL(file);
		}

		return { t, onFileChange }
	}
})
