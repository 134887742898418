import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-btn__content" }
const _hoisted_2 = {
  key: 0,
  class: "app-btn__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type), {
    to: _ctx.to,
    type: "button",
    class: _normalizeClass(_ctx.classes),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event))),
    style: _normalizeStyle(`border-radius: ${_ctx.radius}px`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_app_icon, {
              icon: "refresh",
              size: 16
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["to", "class", "disabled", "style"]))
}