export default {
	noResultsFound: "No results found",
	noItemsSelect: "No items select",
	remove: "Are you sure you want to remove {name}?",
	success: {
		added: "{name} successfully added",
		edited: "{name} successfully edited",
		removed: "{name} successfully removed",
		blocked: "{name} successfully blocked",
		unblocked: "{name} successfully unblocked",
		fixed: "{name} successfully fixed",
		unfixed: "{name} successfully unfixed"
	}
}