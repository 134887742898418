import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class TagList extends PagedQueryResult<Tag> {
	constructor(copy?: Partial<TagList>) {
		super();
		Object.assign(this, copy);
	}
}

export class TagQueryParams extends PagedQueryParams {
	name?: string
}

export type Basic = {
	id_type_tag: number
	comment: string
};

export type Tag = Basic & {
	id: number
	date_time_start: string
	date_time_end: string
	id_enterprise: number
};

export type AddTagCommand = Basic & {
	id_enterprise: number
};

export type EditTagCommand = Basic & {
	id: number
};

export interface TagService {
	list(params: TagQueryParams, id_enterprise: number, cancelToken: any): Promise<TagList>;
	listFree(params: TagQueryParams, id_enterprise: number): Promise<TagList>;
	add(params: AddTagCommand): Promise<Tag>;
	edit(params: EditTagCommand): Promise<Tag>;
	remove(id_tag: number): Promise<null>;
	block(id_tag: number): Promise<null>;
	unblock(id_tag: number): Promise<null>;
}
