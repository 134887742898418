export class PagedQueryResult<T> {
	total: number = 0;
	size: number = 0;
	current: number = 1;
	last: number = 1;
	items: T[] = [];
}

export class PagedQueryParams {
	pageSize?: number
	pageNumber?: number
	sortCriterion?: string
	sortDirection?: number
}