import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, _mergeProps(_ctx.$attrs, {
    hover: "",
    arrow: "",
    disabled: !_ctx.isLaptop
  }), _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 2
  }, [
    (_ctx.$slots['content'])
      ? {
          name: "content",
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(scope)), undefined, true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["disabled"]))
}