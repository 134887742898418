import {AxiosApiClient} from "@/api/axiosApiClient";
import {LegalPairs} from "@/models";
import {list} from "@/api/list";

export class LegalPairApi extends AxiosApiClient implements LegalPairs.LegalPairService {
	private readonly baseUrl = '/legal_pair/';

	public async list(params: LegalPairs.LegalPairQueryParams = {}, id_enterprise: number): Promise<LegalPairs.LegalPairList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<LegalPairs.LegalPair, LegalPairs.LegalPairQueryParams>(items, params);
	}

	public async listFull(params: LegalPairs.LegalPairQueryParams = {}, id_enterprise: number): Promise<LegalPairs.LegalPairListFull> {
		const items = await this.get(this.baseUrl + 'full/' + id_enterprise);
		return list<LegalPairs.LegalPairFull, LegalPairs.LegalPairQueryParams>(items, params);
	}

	public async add(params: LegalPairs.AddLegalPairCommand): Promise<LegalPairs.LegalPair> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: LegalPairs.EditLegalPairCommand): Promise<LegalPairs.LegalPair> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + in_id);
	}
}