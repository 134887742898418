import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import createStore from '@/store';
import buildAxios from '@/plugins/axios';

import "@/styles/main.scss";

import components from "@/plugins/components";
import i18n from '@/plugins/i18n';
import api from '@/api';

import { setupAuth } from '@/auth';

import '@/plugins/vee-validate';

export default async function () {
	const app = createApp(App);

	const auth = await setupAuth({
		onRedirectCallback: appState => {
			router.push(
				appState && appState.targetUrl
					? appState.targetUrl
					: window.location.pathname
			);
		}
	});
	app.use(auth);

	const store = createStore;

	buildAxios(app.config.globalProperties, store);

	app
		.use(router)
		.use(store)
		.use(components)
		.use(api)
		.use(i18n)

	return { app, router, store };
}
