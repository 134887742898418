import {AxiosApiClient} from "@/api/axiosApiClient";
import {TypeLinks} from "@/models";
import {list} from "@/api/list";

export class TypeLinkApi extends AxiosApiClient implements TypeLinks.TypeLinkService {
	private readonly baseUrl = '/type_link/';

	public async list(params: TypeLinks.TypeLinkQueryParams = {}, id_enterprise: number): Promise<TypeLinks.TypeLinkList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<TypeLinks.TypeLink, TypeLinks.TypeLinkQueryParams>(items, params);
	}

	public async add(params: TypeLinks.AddTypeLinkCommand): Promise<TypeLinks.TypeLink> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: TypeLinks.EditTypeLinkCommand): Promise<TypeLinks.TypeLink> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + in_id);
	}

	public async block(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + in_id);
	}

	public async unblock(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + in_id);
	}
}