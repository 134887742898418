
	import {defineComponent} from 'vue';

	export default defineComponent({
		props: {
			icon: { required: true },
			size: {
				type: Number,
				default: 24
			},
			stroke: {
				type: Boolean,
				default: false
			}
		}
	});
