import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from "vue-router";
import { routeGuard } from "@/auth";
import i18n from '@/plugins/i18n';
const t = i18n.global.t;

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "dashboard",
		redirect: {name: 'map'}
	},
	{
		path: "/groups",
		name: "groups",
		meta: { title: t("title.groups") },
		component: () => import(/* webpackChunkName: "groups" */ "@/views/groups/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/enterprises",
		name: "enterprises",
		meta: { title: t("title.enterprises") },
		component: () => import(/* webpackChunkName: "enterprises" */ "@/views/enterprises/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/type_tags",
		name: "type_tags",
		meta: { title: t("title.typeTags") },
		component: () => import(/* webpackChunkName: "type_tags" */ "@/views/typeTags/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/type_resources",
		name: "type_resources",
		meta: { title: t("title.typeResources") },
		component: () => import(/* webpackChunkName: "type_resources" */ "@/views/typeResources/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/type_labels",
		name: "type_labels",
		meta: { title: t("title.typeLabels") },
		component: () => import(/* webpackChunkName: "type_labels" */ "@/views/typeLabels/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/kind_type_resources",
		name: "kind_type_resources",
		meta: { title: t("title.kindTypeResources") },
		component: () => import(/* webpackChunkName: "kind_type_resources" */ "@/views/kindTypeResources/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/rules",
		name: "rules",
		meta: { title: t("title.rules") },
		component: () => import(/* webpackChunkName: "rules" */ "@/views/rules/Listing.vue"),
		beforeEnter: routeGuard
	},

	// remove pages
	{
		path: "/legal_pairs",
		name: "legal_pairs",
		meta: { title: t("title.legalPairs") },
		component: () => import(/* webpackChunkName: "legal_pairs" */ "@/views/legalPairs/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/rules_deprecated",
		name: "rules_deprecated",
		meta: { title: t("title.rules") },
		component: () => import(/* webpackChunkName: "rules_deprecated" */ "@/views/rulesDeprecated/Listing.vue"),
		beforeEnter: routeGuard
	},

	{
		path: "/type_links",
		name: "type_links",
		meta: { title: t("title.links") },
		component: () => import(/* webpackChunkName: "type_links" */ "@/views/typeLinks/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/tags",
		name: "tags",
		meta: { title: t("title.tags") },
		component: () => import(/* webpackChunkName: "tags" */ "@/views/tags/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/resources",
		name: "resources",
		meta: { title: t("title.resources") },
		component: () => import(/* webpackChunkName: "resources" */ "@/views/resources/Listing.vue"),
		beforeEnter: routeGuard
	},
	{
		path: "/map",
		component: () => import(/* webpackChunkName: "map" */ "@/views/maps/Listing.vue"),
		beforeEnter: routeGuard,
		redirect: {name: 'map'},
		children: [
			{
				path: '',
				name: 'map',
				meta: { title: t("title.map") },
				component: () => import(/* webpackChunkName: "map" */ "@/views/maps/components/typeResources/Listing.vue"),
			},
			{
				path: ':idTypeRes',
				component: RouterView,
				redirect: {name: 'maps'},
				children: [
					{
						path: '',
						name: 'maps',
						meta: { title: t("title.map") },
						component: () => import(/* webpackChunkName: "map" */ "@/views/maps/components/maps/Listing.vue"),
					},
					{
						path: ':idRes',
						name: 'linkedResources',
						meta: { title: t("title.map") },
						component: () => import(/* webpackChunkName: "map" */ "@/views/maps/components/linkedResources/Listing.vue"),
					}
				]
			}
		]
	},
	{
		path: "/searches",
		name: "searches",
		meta: { title: t("title.searches") },
		component: () => import(/* webpackChunkName: "searches" */ "@/views/searches/Listing.vue"),
		beforeEnter: routeGuard
	}
];

const serviceRoutes: Array<RouteRecordRaw> = [
	{
		path: "/change_enterprise",
		name: "change_enterprise",
		meta: { title: t("title.changeEnterprise") },
		component: () => import(/* webpackChunkName: "change_enterprise" */ "@/views/ChangeEnterprise.vue"),
		beforeEnter: routeGuard
	},
	{
		path: '/404',
		name: '404',
		meta: { title: t("title.pageNotFound") },
		component: () => import('@/views/404.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: { name: '404' }
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		...routes,
		...serviceRoutes
	]
});

router.beforeEach((to, from, next) => {
	document.title = (to.params.id ? `${to.params.id} - ` : '') + (to.meta.title ? to.meta.title : t("global.administrationWorkflow")) + ` - ${process.env.VUE_APP_TITLE}`;
	next();
});

export default router;
