import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, _mergeProps(_ctx.$attrs, {
    "enter-active-class": "enter-active",
    "leave-active-class": "leave-active"
  }, _toHandlers(_ctx.listeners)), {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["transition-container", _ctx.cssClass])
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16))
}