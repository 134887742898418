import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["app-icon", {"stroke": _ctx.stroke}]),
    style: _normalizeStyle(`width: ${_ctx.size}px; height: ${_ctx.size}px;`),
    innerHTML: require(`!html-loader!@/assets/svg/${_ctx.icon}.svg`)
  }, null, 14, _hoisted_1))
}