export default {
	add: "Add",
	save: "Save",
	edit: "Edit",
	remove: "Remove",
	copy: "Copy",
	search: "Search",
	details: "Details",
	close: "Close",
	block: "Block",
	ubblock: "Unblock",
	history: "History",
	clear: "Clear",
	show: "Show",
	hide: "Hide"
}