import {AxiosApiClient} from "@/api/axiosApiClient";
import {Resources} from "@/models";
import {list} from "@/api/list";

export class ResourceApi extends AxiosApiClient implements Resources.ResourceService {
	private readonly baseUrl = '/resources/';

	public async list(params: Resources.ResourceQueryParams = {}, id_enterprise: number): Promise<Resources.ResourceList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<Resources.Resource, Resources.ResourceQueryParams>(items, params);
	}

	public async listByType(params: Resources.ResourceQueryParams = {}, id_enterprise: number, id_type_res: number): Promise<Resources.ResourceList> {
		const items = await this.get(this.baseUrl + 'by_type/' + id_enterprise + '/' + id_type_res);
		return list<Resources.Resource, Resources.ResourceQueryParams>(items, params);
	}

	public async listByLabel(params: Resources.ResourceQueryParams = {}, label: string): Promise<Resources.ResourceList> {
		const items = await this.get(this.baseUrl + 'by_label/' + label);
		return list<Resources.Resource, Resources.ResourceQueryParams>(items, params);
	}

	public async add(params: Resources.AddResourceCommand): Promise<Resources.Resource> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: Resources.EditResourceCommand): Promise<Resources.Resource> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(id_res: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + id_res);
	}

	public async block(id_res: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + id_res);
	}

	public async unblock(id_res: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + id_res);
	}
}