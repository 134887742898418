import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class KindTypeResourceList extends PagedQueryResult<KindTypeResource> {
	constructor(copy?: Partial<KindTypeResourceList>) {
		super();
		Object.assign(this, copy);
	}
}

export class KindTypeResourceQueryParams extends PagedQueryParams {}

export type Basic = {
	name: string
	comment: string
};

export type KindTypeResource = Basic & {
	id: number
    date_time_start: string
    date_time_end: string
    id_enterprise: number
};

export type AddKindTypeResourceCommand = Basic & {
	id_enterprise: number
};

export type EditKindTypeResourceCommand = Basic & {
	id: number
};

export interface KindTypeResourceService {
	list(params: KindTypeResourceQueryParams, id_enterprise: number): Promise<KindTypeResourceList>;
	add(params: AddKindTypeResourceCommand): Promise<KindTypeResource>;
	edit(params: EditKindTypeResourceCommand): Promise<KindTypeResource>;
	remove(in_id: number): Promise<null>;
	block(in_id: number): Promise<null>;
	unblock(in_id: number): Promise<null>;
}
