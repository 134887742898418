
	import { defineComponent, ref, watch } from 'vue'

	export default defineComponent({
		name: "AppTextField",
		emits: ['update:modelValue', 'blur'],
		props: {
			name: String,
			modelValue: {
				type: String,
				default: ""
			},
			label: String,
			placeholder: String,
			type: {
				type: String,
				default: "text"
			},
			errorMessage: String,
			iconPrepend: {
				type: Boolean,
				default: false
			},
			iconAppend: {
				type: Boolean,
				default: false
			},
			iconAppendAction: Function,
			hideDetails: {
				type: Boolean,
				default: false
			},
			hint: String,
			isAbsolutePositionDetails: {
				type: Boolean,
				default: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			readonly: {
				type: Boolean,
				default: false
			},
			styled: {
				type: String,
				default: 'outlined'
			}
		},
		setup(props, {emit, slots}) {
			const innerValue = ref("" as string);

			const changeValue = ($event: any, name: any) => {
				let v = $event.target.value;
				innerValue.value = v;
				emit(name, v);
			}

			innerValue.value = props.modelValue ? props.modelValue : "";

			watch(() => props.modelValue, () => innerValue.value = props.modelValue);

			const hasSlot = (name = 'default') => !!slots[ name ];

			return {
				innerValue,
				changeValue,
				hasSlot
			}
		}
	})
