import {AxiosApiClient} from "@/api/axiosApiClient";
import {Enterprises} from "@/models";
import {list} from "@/api/list";

export class EnterpriseApi extends AxiosApiClient implements Enterprises.EnterpriseService {
	private readonly baseUrl = '/enterprise/';

	public async list(params: Enterprises.EnterpriseQueryParams = {}): Promise<Enterprises.EnterpriseList> {
		const items = await this.get(this.baseUrl);
		return list<Enterprises.Enterprise, Enterprises.EnterpriseQueryParams>(items, params);
	}

	public async add(params: Enterprises.AddEnterpriseCommand): Promise<Enterprises.Enterprise> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: Enterprises.EditEnterpriseCommand): Promise<Enterprises.Enterprise> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(id_enterprise: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + id_enterprise);
	}

	public async block(id_enterprise: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + id_enterprise);
	}

	public async unblock(id_enterprise: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + id_enterprise);
	}
}