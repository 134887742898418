import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class RuleList extends PagedQueryResult<Rule> {
	constructor(copy?: Partial<RuleList>) {
		super();
		Object.assign(this, copy);
	}
}

export class RuleQueryParams extends PagedQueryParams {}

export type Basic = {
	id_legal_pair: number
	id_type_link: number
};

export type Rule = Basic & {
	id: number
    name1: string
    name2: string
    link_name: string
    link_name_lr: string
    link_name_rl: string
    if_friendly: true
    id_enterprise: number
};

export type AddRuleCommand = Basic;
export type AddPairRuleCommand = {
	id_type_res1: number
	id_type_res2: number
	master_resource_position: number
	if_friendly: boolean
    id_enterprise: number
	link: number
	link_close_list: number[]
};

export interface RuleService {
	list(params: RuleQueryParams, id_enterprise: number): Promise<RuleList>;
	add(params: AddRuleCommand): Promise<Rule>;
	remove(legal_pair_links_id: number): Promise<null>;
	addPair(params: AddPairRuleCommand): Promise<{id: number}>;
}
