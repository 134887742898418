import {AxiosApiClient} from "@/api/axiosApiClient";
import {Labels} from "@/models";
import {list} from "@/api/list";

export class LabelApi extends AxiosApiClient implements Labels.LabelService {
	private readonly baseUrl = '/labels/';

	public async list(params: Labels.LabelQueryParams = {}, id_tag: number): Promise<Labels.LabelList> {
		const items = await this.get(this.baseUrl + id_tag);
		return list<Labels.Label, Labels.LabelQueryParams>(items, params);
	}

	public async listFull(params: Labels.LabelQueryParams = {}, id_tag: number): Promise<Labels.LabelFullList> {
		const items = await this.get(this.baseUrl + 'full/' + id_tag);
		return list<Labels.LabelFull, Labels.LabelQueryParams>(items, params);
	}

	public async add(params: Labels.AddLabelCommand): Promise<Labels.Label> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: Labels.EditLabelCommand): Promise<Labels.Label> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + id);
	}
}