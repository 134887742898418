import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class MapsList extends PagedQueryResult<Map> {
	constructor(copy?: Partial<MapsList>) {
		super();
		Object.assign(this, copy);
	}
}

export class LinkedResourcesList extends PagedQueryResult<LinkedResource> {
	constructor(copy?: Partial<LinkedResourcesList>) {
		super();
		Object.assign(this, copy);
	}
}

export class LinkedResourcesHistoryList extends PagedQueryResult<LinkedResourceHistory> {
	constructor(copy?: Partial<LinkedResourcesHistoryList>) {
		super();
		Object.assign(this, copy);
	}
}

export class MapQueryParams extends PagedQueryParams {}

export type LinkedFilterQueryParams = PagedQueryParams & {
	id_res: number
	lst_of_links: number[]
	lst_of_types: number[]
}

export type Map = {
	id_res_name1: number
	name1: string
	description1: string
	link: string
	id_res_name2: number
	name2: string
	description2: string
	id_type_res1: number
	id_type_res2: number
	id_pair: number
	avatar_1: string
	avatar_2: string
};

export type LinkedResource = {
	id_res: number
    name: string
    description: string
    id_link: number
    link: string
    external_id: string
    external_id_type: string
	fixed: boolean
	id_pair: number
}

export type LinkedResourceHistory = LinkedResource & {
    id_pair: number
    avatar: string
    date_time_start: string
    date_time_end: string
}


export interface MapService {
	maps(params: MapQueryParams, id_enterprise: number, id_type_res: number): Promise<MapsList>;
	search(id_enterprise: number, name: string, cancelToken: any): Promise<Map[]>;
	linkedResources(params: MapQueryParams, id_res: number): Promise<LinkedResourcesList>;
	history(params: MapQueryParams, id_res: number): Promise<LinkedResourcesHistoryList>;
	linkedFilter(params: LinkedFilterQueryParams): Promise<LinkedResourcesList>;
	linkedFilterHistory(params: LinkedFilterQueryParams): Promise<LinkedResourcesHistoryList>;
}
