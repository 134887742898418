import { createStore } from 'vuex';

import globals from './modules/globals';
import errors from './modules/errors';
import notification from './modules/notification';
import dictionaries from './modules/dictionaries'

export default createStore({
	modules: {
		globals,
		errors,
		notification,
		dictionaries
	}
})