import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-badge__wrapper" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["app-badge", {"app-badge__dot": _ctx.dot}])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["app-badge__badge", {[_ctx.color]: _ctx.color}]),
        innerHTML: _ctx.text
      }, null, 10, _hoisted_2)
    ])
  ], 2))
}