
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
	props: {
		isOpened: Boolean,
		left: Boolean,
		right: Boolean,
		top: Boolean,
		bottom: Boolean,
		disabled: Boolean,
	},
	emits: ['opened', 'closed'],

	setup(props, { emit }) {
		const opened = ref(false)
		const moveToTop = ref(false)

		function open() {
            if (props.disabled) return;

            if (!opened.value) {
                checkTopPosition()
                opened.value = true
                emit('opened')
            } else {
                close()
            }
        }
		function close() {
			if (!opened.value) return;

			opened.value = false
			emit('closed')
			const timer = setTimeout(() => {
				moveToTop.value = false
				clearTimeout(timer)
			}, 400)
		}

		watch(() => props.isOpened, () => {
			if(props.isOpened) return;
			close();
		})

		const wrapper = ref<HTMLElement | null>(null)
		const popup = ref<HTMLElement | null>(null)

		function checkTopPosition() {
			if (props.top || props.bottom || !wrapper.value || !popup.value) return;

            const offset = document.documentElement.clientHeight - wrapper.value.getBoundingClientRect().top - popup.value.clientHeight
            if (offset < 0) {
                moveToTop.value = true
            }
		}

		return { opened, open, close, wrapper, popup, moveToTop }
	}
})
