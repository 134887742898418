import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, withKeys as _withKeys, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "select-container" }
const _hoisted_2 = { class: "select-control pl-3 pr-3 border border-radius" }
const _hoisted_3 = {
  key: 1,
  class: "select-control-icon"
}
const _hoisted_4 = { class: "select-list" }
const _hoisted_5 = {
  key: 0,
  class: "app-label mt-4 ml-3"
}
const _hoisted_6 = ["onClick", "onKeyup"]
const _hoisted_7 = ["onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_fade_transition = _resolveComponent("app-fade-transition")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _component_app_expand_transition = _resolveComponent("app-expand-transition")!
  const _component_app_click_away = _resolveComponent("app-click-away")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-select", {"app-select-active": _ctx.isOptionsListShown, "disabled": _ctx.disabled}])
  }, [
    _createVNode(_component_app_click_away, { onClickedAway: _ctx.closeOptions }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchOptions && _ctx.switchOptions(...args))),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.switchOptions && _ctx.switchOptions(...args)), ["enter"])),
            class: "pointer select-control-wrap"
          }, [
            _renderSlot(_ctx.$slots, "control", _normalizeProps(_guardReactiveProps(_ctx.openOptions)), () => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", {
                  class: "select-control-label",
                  style: _normalizeStyle(!_ctx.currentLabel ? "opacity: 0.4;" : "")
                }, _toDisplayString(_ctx.currentLabel ?? '---'), 5),
                _createVNode(_component_app_fade_transition, { show: true }, {
                  default: _withCtx(() => [
                    (_ctx.isClearButtonShown)
                      ? (_openBlock(), _createBlock(_component_app_button, {
                          key: 0,
                          icon: "",
                          onClick: _withModifiers(_ctx.clearSelection, ["stop"]),
                          class: "select-control-clear mr-n2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_icon, {
                              icon: "cross",
                              size: 14
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (_ctx.hasSlot("iconAppend"))
                            ? _renderSlot(_ctx.$slots, "iconAppend", { key: 0 })
                            : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                _createVNode(_component_app_icon, {
                                  icon: "arrow-down",
                                  size: 18
                                })
                              ]))
                        ], 64))
                  ]),
                  _: 3
                })
              ])
            ])
          ], 32),
          _createVNode(_component_app_expand_transition, {
            show: _ctx.isOptionsListShown,
            cssClass: "select-list-wrapper box-shadow border-radius"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_perfect_scrollbar, {
                  class: "app-scrollbar",
                  ref: "scrollWrapper"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.isSubGroup)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupBy(_ctx.items, _ctx.isSubGroupName), (group, i) => {
                            return (_openBlock(), _createElementBlock("div", { key: i }, [
                              (i != "undefined")
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(i), 1))
                                : _createCommentVNode("", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (item, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: index,
                                  class: _normalizeClass(["item pointer py-2 px-3", { 'item-active': _ctx.isItemSelected(item.id) }]),
                                  tabindex: "0",
                                  onClick: ($event: any) => (_ctx.selectItem(item)),
                                  onKeyup: _withKeys(($event: any) => (_ctx.selectItem(item)), ["enter"])
                                }, _toDisplayString(item[_ctx.itemName]), 43, _hoisted_6))
                              }), 128))
                            ]))
                          }), 128))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: _normalizeClass(["item pointer py-2 px-3", { 'item-active': _ctx.isItemSelected(item.id) }]),
                              tabindex: "0",
                              onClick: ($event: any) => (_ctx.selectItem(item)),
                              onKeyup: _withKeys(($event: any) => (_ctx.selectItem(item)), ["enter"])
                            }, _toDisplayString(item[_ctx.itemName]), 43, _hoisted_7))
                          }), 128))
                    ])
                  ]),
                  _: 1
                }, 512)
              ])
            ]),
            _: 1
          }, 8, ["show"])
        ])
      ]),
      _: 3
    }, 8, ["onClickedAway"])
  ], 2))
}