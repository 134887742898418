import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class EnterpriseList extends PagedQueryResult<Enterprise> {
	constructor(copy?: Partial<EnterpriseList>) {
		super();
		Object.assign(this, copy);
	}
}

export class EnterpriseQueryParams extends PagedQueryParams {}

export type Basic = {
	name: string
	address: string
	other: {[key: string]: any}
};

export type Enterprise = Basic & {
	id: number
	id_group: number
    date_time_start: string
    date_time_end: string
};

export type AddEnterpriseCommand = Basic & {
	id: number
	id_group?: number
};

export type EditEnterpriseCommand = Basic & {
	id: number
	id_group?: number
};

export interface EnterpriseService {
	list(params: EnterpriseQueryParams): Promise<EnterpriseList>;
	add(params: AddEnterpriseCommand): Promise<Enterprise>;
	edit(params: EditEnterpriseCommand): Promise<Enterprise>;
	remove(id_enterprise: number): Promise<null>;
	block(id_enterprise: number): Promise<null>;
	unblock(id_enterprise: number): Promise<null>;
}
