
import createApp from './main'

declare global {
    interface Window {
        __INITIAL_STATE__: any
    }
}

(async () => {
	const { app, router, store } = await createApp()
	await router.isReady()

	if (window.__INITIAL_STATE__) { // store state injection
		store.replaceState(window.__INITIAL_STATE__)
		delete window.__INITIAL_STATE__
	}
	app.mount('#app')
})()
