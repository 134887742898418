import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-snackbar__wrapper" }
const _hoisted_2 = { class: "app-snackbar__content" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle-2"
}
const _hoisted_4 = {
  key: 1,
  class: "text-caption mt-1"
}
const _hoisted_5 = { class: "app-snackbar__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "translate-right" }, {
    default: _withCtx(() => [
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["app-snackbar", `${_ctx.position} ${_ctx.status}`])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.notification.content.title)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.notification.content.title), 1))
                  : _createCommentVNode("", true),
                (_ctx.notification.content.text)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.notification.content.text), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "app-snackbar__action-button",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit("close-snackbar")), ["stop"]))
                }, [
                  _createVNode(_component_app_icon, {
                    icon: "cross",
                    size: 8
                  })
                ])
              ])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}