import axios from "axios";

export abstract class AxiosApiClient {
	protected async post(url: string, fields: any): Promise<any> {
		return (await axios({ method: 'post', url, ...{data: fields} })).data;
	}
	protected async get(url: string, fields: any = {}, cancelToken?: any): Promise<any> {
		return (await axios({ method: 'get', url, ...{params: fields}, cancelToken })).data
	}
	protected async put(url: string, fields: any = {}): Promise<any> {
		return (await axios({ method: 'put', url, ...{data: fields} })).data;
	}
	protected async deleteRequest(url: string, fields: any = {}): Promise<any> {
		return (await axios({ method: 'delete', url, ...{params: fields} })).data;
	}
}