import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-popup"
}
const _hoisted_2 = { class: "app-popup__inner" }
const _hoisted_3 = {
  key: 0,
  class: "backdrop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "popup",
    onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)), ["esc"])),
    tabindex: "0"
  }, [
    _createVNode(_Transition, { name: "scale" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "app-popup__content",
                  style: _normalizeStyle(`max-width: ${_ctx.width}px`)
                }, [
                  _renderSlot(_ctx.$slots, "default")
                ], 4)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ], 544))
}