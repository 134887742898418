import global from "./global";
import title from "./title";
import form from './form';
import button from './button';
import dictionary from './dictionary';

const content = {
	global,
	title,
	form,
	button,
	dictionary
}

export default content;
