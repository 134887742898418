import {getCookie} from '@/helpers/cookie';

interface GlobalState {
	enterpriseId: number|null
	pageSize: number
	detailsTypeResource: boolean
	detailsResource: boolean
	mapView: string
}

const state = (): GlobalState => ({
	enterpriseId: getCookie("enterpriseId") ? Number(getCookie("enterpriseId")) : null,
	pageSize: getCookie("pageSize") ? Number(getCookie("pageSize")) : 5,
	detailsTypeResource: false,
	detailsResource: false,
	mapView: window.localStorage.getItem('mapView') ? String(window.localStorage.getItem('mapView')) : 'grid'
});

const mutations = {
	setEnterpriseId(state: GlobalState, enterpriseId: number) {
		state.enterpriseId = enterpriseId;
	},
	setPageSize(state: GlobalState, pageSize: number) {
		state.pageSize = pageSize;
	},
	setDetailsTypeResource(state: GlobalState, details: boolean) {
		state.detailsTypeResource = details;
	},
	setDetailsResource(state: GlobalState, details: boolean) {
		state.detailsResource = details;
	},
	setMapView(state: GlobalState, mapView: string) {
		state.mapView = mapView;
		window.localStorage.setItem('mapView', mapView);
	}
};

export default {
	namespaced: true,
	state,
	mutations
};
