import axios from 'axios';

const buildAxios = (globalProperties: Record<string, any>, store: any) => {
	globalProperties.$http = axios;
	globalProperties.$http.defaults.baseURL = process.env.VUE_APP_API_GATEWAY_URL;

	globalProperties.$http.interceptors.request.use(async (config: any) => {
		const token = globalProperties.$auth.token.value;
		if (token)
			config.headers['Authorization'] = `Bearer ${token}`;

		return config;
	});

	globalProperties.$http.interceptors.response.use(
		(res: any) => res,
		(error: any) => {
			if(error?.response?.data)
				store.commit('notification/errorMessage', {title: error.message, text: error.response.data.detail});
			return Promise.reject(error);
		}
	)
}

export default buildAxios;
