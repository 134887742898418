import {AxiosApiClient} from "@/api/axiosApiClient";
import {Tags} from "@/models";
import {list} from "@/api/list";

export class TagApi extends AxiosApiClient implements Tags.TagService {
	private readonly baseUrl = '/tags/';

	public async list(params: Tags.TagQueryParams = {}, id_enterprise: number, cancelToken: any): Promise<Tags.TagList> {
		const items = await this.get(this.baseUrl + id_enterprise, params, cancelToken);

		const name = params.name?.length ? params.name : '';

		const filterName = name.length
			? items.filter((item) => item.comment.toLowerCase().includes(name.toLowerCase()))
			: items

		return list<Tags.Tag, Tags.TagQueryParams>(filterName, params);
	}

	public async listFree(params: Tags.TagQueryParams = {}, id_enterprise: number): Promise<Tags.TagList> {
		const items = await this.get(this.baseUrl + 'free/' + id_enterprise);
		return list<Tags.Tag, Tags.TagQueryParams>(items, params);
	}

	public async add(params: Tags.AddTagCommand): Promise<Tags.Tag> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: Tags.EditTagCommand): Promise<Tags.Tag> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(id_tag: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + id_tag);
	}

	public async block(id_tag: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + id_tag);
	}

	public async unblock(id_tag: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + id_tag);
	}
}