
	import { defineComponent, ref, computed, watch, onMounted, PropType } from 'vue';
	import { constants } from '@/helpers/constants';

	type Notify = { isActive: boolean; status: string; content: { title: string | null; text?: string | null; } };

	export default defineComponent({
		name: 'AppSnackbar',
		emits: ["close-snackbar"],
		props: {
			notification: {
				type: Object as PropType<Notify>,
				required: true
			},
			position: {
				type: String,
				default: 'top right'
			},
			interval: {
				type: Number,
				default: constants.limitlessPageSize
			}
		},
		setup(props, {emit}) {
			const isActive = computed(() => props.notification.isActive);
			const status = computed(() => props.notification.status);

			const timer = ref();

			function snackbar() {
				clearTimeout(timer.value)

				if(!isActive.value) return;

				timer.value = setTimeout(() => emit("close-snackbar"), props.interval);
			}

			onMounted(() => snackbar());
			watch(isActive, () => snackbar());

			return { isActive, status }
		}
	})
