
	import {defineComponent} from 'vue';

	export default defineComponent({
		props: {
			color: {
				type: String,
				default: 'default'
			},
			text: {
				type: String,
				default: null
			},
			dot: {
				type: Boolean,
				default: false
			}
		}
	});
