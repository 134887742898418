import { PagedQueryResult, PagedQueryParams } from "@/models/query";
import { Maps } from "@/models";

export class SearchList extends PagedQueryResult<Search> {
	constructor(copy?: Partial<SearchList>) {
		super();
		Object.assign(this, copy);
	}
}

export type Search = {
	[key: string]: any
};

export type TableName = {
	table_name: string
	id?: string
	name?: string
};

export type ColumnName = {
	column_name: string
	data_type: string
	id?: string
};

export class SearchQueryParams extends PagedQueryParams {
	table?: string
	field?: string
	value?: string
	data_type?: string
	id_type_res?: number
}

export type jsonKey = {
	id: string,
	name: string
};

export interface SearchService {
	listTables(): Promise<TableName[]>;
	listFields(table_name: string): Promise<ColumnName[]>;
	search(params: SearchQueryParams): Promise<SearchList>;
	jsonKeys(id_enterprise: number, type_res: number): Promise<jsonKey[]>;
	resources(id_enterprise: number, type_res: number, key: string, value: string, cancelToken: any): Promise<Maps.Map[]>;
}
