
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "AppNavigationList",
	props: {
		items: {
			type: Array as PropType<{link: string, name: string, icon?: string}[]>,
			default: null
		}
	}
});
