import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mx-n1 align-center"
}
const _hoisted_2 = { class: "col-auto py-0 px-1" }
const _hoisted_3 = { class: "app-file-upload-img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col py-0 px-1" }
const _hoisted_6 = { class: "text-body-2" }
const _hoisted_7 = { class: "col-auto py-0 px-1" }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.avatar.indexOf("base64") > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.avatar,
                alt: ""
              }, null, 8, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t("global.photoUsed")), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_app_button, {
              icon: "",
              class: "mt-1 text-red",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("update-file", "")))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_icon, {
                  icon: "cross",
                  size: 14
                })
              ]),
              _: 1
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("input", {
            type: "file",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
            accept: "image/*"
          }, null, 32)
        ]))
  ]))
}