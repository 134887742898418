import createAuth0Client from '@auth0/auth0-spa-js'
import { computed, reactive, watchEffect } from 'vue'
import {getCookie} from '@/helpers/cookie';

let client
const state = reactive({
	loading: true,
	isAuthenticated: false,
	user: {},
	token: {},
	popupOpen: false,
	error: null,
})

async function loginWithPopup(options, config) {
	state.popupOpen = true

	try {
		await client.loginWithPopup(options, config);
		state.token = await getTokenSilently();
	} catch (e) {
		console.error(e)
	} finally {
		state.popupOpen = false
	}

	state.user = await client.getUser()
	state.isAuthenticated = true
}

async function handleRedirectCallback() {
	state.loading = true

	try {
		await client.handleRedirectCallback()
		state.user = await client.getUser()
		state.token = await getTokenSilently();
		state.isAuthenticated = true
	} catch (e) {
		state.error = e
	} finally {
		state.loading = false
	}
}

function loginWithRedirect(o) {
	return client.loginWithRedirect(o)
}

function getIdTokenClaims(o) {
	return client.getIdTokenClaims(o)
}

function getTokenSilently(o) {
	return client.getTokenSilently(o)
}

function getTokenWithPopup(o) {
	return client.getTokenWithPopup(o)
}

function logout(o) {
	return client.logout(o)
}

export const authPlugin = {
	isAuthenticated: computed(() => state.isAuthenticated),
	loading: computed(() => state.loading),
	token: computed(() => state.token),
	user: computed(() => state.user),
	getIdTokenClaims,
	getTokenSilently,
	getTokenWithPopup,
	handleRedirectCallback,
	loginWithRedirect,
	loginWithPopup,
	logout
}

export const routeGuard = (to, from, next) => {
	const { isAuthenticated, loading } = authPlugin

	const verify = () => {
		if (isAuthenticated.value) {
			if(!getCookie('enterpriseId'))
				if(to.name === 'groups' || to.name === 'enterprises' || to.name === 'change_enterprise') {
					return next();
				} else {
					return next('change_enterprise');
				}

			return next();
		}

		loginWithRedirect({ appState: { targetUrl: to.fullPath } })
	}

	if (!loading.value) {
		return verify()
	}

	watchEffect(() => {
		if (loading.value === false) {
			return verify()
		}
	})
}

const DEFAULT_REDIRECT_CALLBACK = () => {
	window.history.replaceState({}, document.title, window.location.pathname);
}

export const setupAuth = async ({
	onRedirectCallback = (appState) => DEFAULT_REDIRECT_CALLBACK(appState)
}) => {
	client = await createAuth0Client({
		domain: process.env.VUE_APP_IDENTITY_SERVER,
		client_id: process.env.VUE_APP_CLIENT_ID,
		audience: process.env.VUE_APP_API_GATEWAY_URL,
		redirect_uri: window.location.origin
		// scope: 'write:super_admin write:api'
	})

	try {
		if (
			window.location.search.includes('code=') &&
			window.location.search.includes('state=')
		) {
			const { appState } = await client.handleRedirectCallback()
			onRedirectCallback(appState);
		}
	} catch (e) {
		state.error = e
	} finally {
		state.isAuthenticated = await client.isAuthenticated()
		state.user = await client.getUser()
		try {
			state.token = await getTokenSilently();
		} catch {
			state.token = null;
		}
		state.loading = false
	}

	return {
		install: (app) => {
			app.config.globalProperties.$auth = authPlugin;
			app.provide('$auth', authPlugin);
		}
	}
}
