interface INotifyState {
	isActive: boolean;
	status: string;
	content: Content;
}

type Content = {
	title?: string;
	text?: string;
}

const state = (): INotifyState => ({
	isActive: false,
	status: "success",
	content: {},
});

const mutations = {
	successMessage(
		state: INotifyState,
		content: Content
	): void {
		state.isActive = true;
		state.status = "success";
		state.content = content;
	},
	errorMessage(
		state: INotifyState,
		content: Content
	): void {
		state.isActive = true;
		state.status = "error";
		state.content = content;
	},
	clearMessage(state: INotifyState): void {
		state.isActive = false;
		state.status = "success";
		state.content = {};
	},
};

export default {
	namespaced: true,
	state,
	mutations
};
