export default {
	validate: {
		required: "This field is required",
		numeric: "This field must be numeric",
		min: "This field must contain at least {limit} characters",
		minValue: "This field must be greater than {min}",
		maxValue: "This field must be less than {max}",
		confirmed: "Passwords must match"
	},
	allRequired: "All fields must be filled",
	noTagSelection: "No tag selection",
}