import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-pagination"
}
const _hoisted_2 = { class: "app-pagination-row" }
const _hoisted_3 = { class: "app-pagination-pages" }
const _hoisted_4 = {
  key: 1,
  class: "break"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_ctx.pageCount > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_button, {
            class: _normalizeClass(["app-pagination-button", `${ _ctx.pageNumber == 1 ? 'hidden' : '' }`]),
            onClick: _ctx.changePageToPrev
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, { icon: "arrow-full-back" })
            ]),
            _: 1
          }, 8, ["class", "onClick"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedPages, (page, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: page + index
              }, [
                (page !== _ctx.pageBreakId)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.changePage(page)),
                      class: _normalizeClass(["app-pagination-button", `${ page === _ctx.pageNumber ? 'active' : '' }`])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(page), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "class"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, "..."))
              ], 64))
            }), 128))
          ]),
          _createVNode(_component_app_button, {
            class: _normalizeClass(["app-pagination-button", `${ _ctx.pageNumber == _ctx.pageCount ? 'hidden' : '' }`]),
            onClick: _ctx.changePageToNext
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, { icon: "arrow-full-forward" })
            ]),
            _: 1
          }, 8, ["class", "onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}