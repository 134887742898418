
import { defineComponent, ref, watch } from 'vue';
import {useRoute} from "vue-router";

export default defineComponent({
	name: 'AppMenu',
	emits: ["menu-changed"],
	setup(_, {emit}) {
		const route = useRoute();

		const isMenuOpened = ref(false);

		watch(() => route.name, () => {
			isMenuOpened.value = false;
			emit("menu-changed", isMenuOpened.value);
		})

		return { isMenuOpened };
	}
})
