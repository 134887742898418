
import { defineComponent, ref } from 'vue'
// @ts-ignore
import Popper from 'vue3-popper'

export default defineComponent({
	name: 'AppTooltip',
	components: { Popper },
	setup() {
		const isLaptop = ref(true);
		isLaptop.value = window.innerWidth >= 960;
		window.addEventListener('resize', () => isLaptop.value = window.innerWidth >= 960);

		return { isLaptop }
	}
})
