
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import { injectOrThrow } from "@/helpers/inject";

export default defineComponent({
	name: 'AppUserControls',
	setup() {
		const { t } = useI18n();
		const {isAuthenticated, user, logout} = injectOrThrow('$auth');

		return { t, isAuthenticated, user, logout };
	}
})
