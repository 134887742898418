
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import { useStore } from "vuex";
import AppLogo from '@/components/header/AppLogo.vue'
import AppMenu from '@/components/header/AppMenu.vue';
import AppUserControls from '@/components/header/AppUserControls.vue';

export default defineComponent({
	name: 'AppHeader',
	emits: ["menu-changed"],
	components: {
		AppLogo,
		AppMenu,
		AppUserControls
	},
	setup() {
		const { t } = useI18n();
		const { state } = useStore();
		return { t, state };
	}
})
