import {AxiosApiClient} from "@/api/axiosApiClient";
import {TypeTags} from "@/models";
import {list} from "@/api/list";

export class TypeTagApi extends AxiosApiClient implements TypeTags.TypeTagService {
	private readonly baseUrl = '/type_tag/';

	public async list(params: TypeTags.TypeTagQueryParams = {}, id_enterprise: number): Promise<TypeTags.TypeTagList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<TypeTags.TypeTag, TypeTags.TypeTagQueryParams>(items, params);
	}

	public async add(params: TypeTags.AddTypeTagCommand): Promise<TypeTags.TypeTag> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: TypeTags.EditTypeTagCommand): Promise<TypeTags.TypeTag> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(type_tag_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + type_tag_id);
	}

	public async block(type_tag_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + type_tag_id);
	}

	public async unblock(type_tag_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + type_tag_id);
	}
}