import {AxiosApiClient} from "@/api/axiosApiClient";
import {TypeResources} from "@/models";
import {list} from "@/api/list";

export class TypeResourceApi extends AxiosApiClient implements TypeResources.TypeResourceService {
	private readonly baseUrl = '/type_res/';

	public async list(params: TypeResources.TypeResourceQueryParams = {}, id_enterprise: number): Promise<TypeResources.TypeResourceList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<TypeResources.TypeResource, TypeResources.TypeResourceQueryParams>(items, params);
	}

	public async add(params: TypeResources.AddTypeResourceCommand): Promise<TypeResources.TypeResource> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: TypeResources.EditTypeResourceCommand): Promise<TypeResources.TypeResource> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(type_res_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + type_res_id);
	}

	public async block(type_res_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + type_res_id);
	}

	public async unblock(type_res_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + type_res_id);
	}
}