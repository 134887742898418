import * as Groups from './groups';
import * as Enterprises from './enterprises';
import * as TypeTags from './typeTags';
import * as TypeResources from './typeResources';
import * as TypeLabels from './typeLabels';
import * as KindTypeResources from './kindTypeResources';
import * as LegalPairs from './legalPairs';
import * as Rules from './rules';
import * as TypeLinks from './typeLinks';
import * as Tags from './tags';
import * as Labels from './labels';
import * as Resources from './resources';
import * as Pairs from './pairs';
import * as Maps from './maps';
import * as Searches from './searches';

export { Groups, Enterprises, TypeTags, TypeResources, TypeLabels, KindTypeResources, LegalPairs, Rules, TypeLinks, Tags, Labels, Resources, Pairs, Maps, Searches };

export default {
	install: (app: any) => {
		app
			.provide('Groups.GroupService', new Groups.GroupApi())
			.provide('Enterprises.EnterpriseService', new Enterprises.EnterpriseApi())
			.provide('TypeTags.TypeTagService', new TypeTags.TypeTagApi())
			.provide('TypeResources.TypeResourceService', new TypeResources.TypeResourceApi())
			.provide('TypeLabels.TypeLabelService', new TypeLabels.TypeLabelApi())
			.provide('KindTypeResources.KindTypeResourceService', new KindTypeResources.KindTypeResourceApi())
			.provide('LegalPairs.LegalPairService', new LegalPairs.LegalPairApi())
			.provide('Rules.RuleService', new Rules.RuleApi())
			.provide('TypeLinks.TypeLinkService', new TypeLinks.TypeLinkApi())
			.provide('Tags.TagService', new Tags.TagApi())
			.provide('Labels.LabelService', new Labels.LabelApi())
			.provide('Resources.ResourceService', new Resources.ResourceApi())
			.provide('Pairs.PairService', new Pairs.PairApi())
			.provide('Maps.MapService', new Maps.MapApi())
			.provide('Searches.SearchService', new Searches.SearchApi())
	}
}