import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class TypeLinkList extends PagedQueryResult<TypeLink> {
	constructor(copy?: Partial<TypeLinkList>) {
		super();
		Object.assign(this, copy);
	}
}

export class TypeLinkQueryParams extends PagedQueryParams {}

export type Basic = {
	name: string
	lr_name: string
	rl_name: string
	mandatory: boolean
	priority: number
	uniq_for_res: number
};

export type TypeLink = Basic & {
	id: number
    date_time_start: string
    date_time_end: string
    id_enterprise: number
};

export type AddTypeLinkCommand = Basic & {
	id_enterprise: number
};

export type EditTypeLinkCommand = Basic & {
	id: number
};

export interface TypeLinkService {
	list(params: TypeLinkQueryParams, id_enterprise: number): Promise<TypeLinkList>;
	add(params: AddTypeLinkCommand): Promise<TypeLink>;
	edit(params: EditTypeLinkCommand): Promise<TypeLink>;
	remove(in_id: number): Promise<null>;
	block(in_id: number): Promise<null>;
	unblock(in_id: number): Promise<null>;
}
