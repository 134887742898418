import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "app-menu",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMenuOpened = !_ctx.isMenuOpened, _ctx.$emit("menu-changed", _ctx.isMenuOpened)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["app-menu-button", {'open': _ctx.isMenuOpened}])
    }, null, 2)
  ]))
}