export default {
	enterprise: "enterprise",
	group: "group",
	name: "name",
	comment: "comment",
	address: "address",
	additionalInformation: "additional information",
	description: "description",
	directory: "directory",
	kindTypeResource: "kind type resource",
	legalPair: "legal pair",
	rule: "rule",
	typeLabel: "type label",
	typeLink: "type link",
	typeLinkClosed: "these links close",
	link: "link",
	typeResource: "type resource",
	typeResources: "type resources",
	typeTag: "type tag",
	status: "status",
	lock: "lock",
	unlock: "unlock",
	descriptionPattern: "description pattern",
	field: "field",
	avatar: "avatar",
	tag: "tag",
	label: "label",
	resource: "resource",
	resources: "resources",
	externalId: "external id",
	externalIdType: "external id type",
	lrName: "left name",
	rlName: "right name",
	priority: "priority",
	uniq_for_res: "uniq for resources",
	mandatory: "mandatory",
	ifFriendly: "if friendly",
	masterResourcePosition: "master resource position",
	left: "left",
	right: "right",
	main: "main",
	types: "types",
	linkName: "link name",
	pair: "pair",
	pairIsRules: "pairs' rules",
	default: "default",
	specific: "specific",
	map: "map",
	all: "all",
	logout: "logout",
	tableName: "table name",
	columnName: "column name",
	value: "value",
	search: "search",
	dateTimeStart: "time start",
	dateTimeEnd: "time end",
	duration: "duration",
	searchBy: "search by",
	fixed: "fixed",
	unfixed: "unfixed"
}