import AppClickAway from "@/components/AppClickAway.vue";
import AppExpandTransition from "@/components/transitions/AppExpandTransition.vue";
import AppFadeTransition from "@/components/transitions/AppFadeTransition.vue";
import AppFloatingWrapper from "@/components/AppFloatingWrapper.vue";

import AppButton from "@/components/AppButton.vue";
import AppTextField from "@/components/AppTextField.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppPagination from "@/components/AppPagination.vue";
import AppSpinner from "@/components/AppSpinner.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppDialog from "@/components/AppDialog.vue";
import AppSnackbar from "@/components/AppSnackbar.vue";
import AppBadge from "@/components/AppBadge.vue";
import AppTooltip from "@/components/AppTooltip.vue";
import AppFileUpload from "@/components/AppFileUpload.vue";
import AppCheckbox from "@/components/AppCheckbox.vue";

const components = {
	AppClickAway,
	AppExpandTransition,
	AppFadeTransition,
	AppFloatingWrapper,

	AppButton,
	AppTextField,
	AppSelect,
	AppAutocomplete,
	AppPagination,
	AppSpinner,
	AppIcon,
	AppDialog,
	AppSnackbar,
	AppBadge,
	AppTooltip,
	AppFileUpload,
	AppCheckbox
}

export default {
	install: (app: any) => {
		Object.entries(components).forEach(([componentName, icon]) => {
			app.component(componentName, icon);
		});
	}
}