interface IErrorState {
	show: boolean;
	showDetails: boolean;
	isError: boolean;
	title: string | null;
	description: string | null;
	isForbidden: boolean;
	response: {};
}

const state: Function = (): IErrorState => ({
	show: true,
	showDetails: true,
	isError: false,
	title: null,
	description: null,
	isForbidden: false,
	response: {},
});

const mutations = {
	setError(state: IErrorState, { error }: any): void {
		state.isForbidden = error.response?.status === 403;

		const message = state.isForbidden ? error.forbidden : error;
		state.title = message.title;
		state.description = message.description;

		if (state.show) {
			state.isError = true;
			state.response = error.response;
		}
	},
	clearError(state: IErrorState) {
		state.title = null;
		state.description = null;
		state.isError = false;
		state.isForbidden = false;
		state.response = {};
	},
};

const actions = {};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
