import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-autocomplete d-flex align-center" }
const _hoisted_2 = {
  key: 2,
  class: "select-control-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_select, {
      items: _ctx.filteredItems,
      disabled: _ctx.disabled,
      clearable: _ctx.clearable,
      selected: _ctx.selected,
      onSelectItem: _ctx.selectItem,
      "item-name": _ctx.itemName,
      isSubGroup: _ctx.isSubGroup,
      isSubGroupName: _ctx.isSubGroupName
    }, {
      control: _withCtx((openOptions) => [
        _createVNode(_component_app_text_field, {
          modelValue: _ctx.searchText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          onInput: ($event: any) => (_ctx.$emit("input", _ctx.searchText), openOptions()),
          placeholder: _ctx.placeholder,
          name: _ctx.inputId,
          iconPrepend: _ctx.iconPrepend,
          iconAppend: _ctx.iconAppend
        }, {
          iconPrepend: _withCtx(() => [
            _createVNode(_component_app_icon, {
              icon: "search",
              size: 16
            })
          ]),
          iconAppend: _withCtx(() => [
            (_ctx.hasSlot("iconAppend"))
              ? _renderSlot(_ctx.$slots, "iconAppend", { key: 0 })
              : (_ctx.clearable && _ctx.searchText.length)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 1,
                    icon: "",
                    onClick: _withModifiers(_ctx.clearSelection, ["stop"]),
                    class: "select-control-clear mr-n2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_icon, {
                        icon: "cross",
                        size: 14
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, [
                    _createVNode(_component_app_icon, {
                      icon: "arrow-down",
                      size: 18
                    })
                  ]))
          ]),
          _: 2
        }, 1032, ["modelValue", "onInput", "placeholder", "name", "iconPrepend", "iconAppend"])
      ]),
      _: 3
    }, 8, ["items", "disabled", "clearable", "selected", "onSelectItem", "item-name", "isSubGroup", "isSubGroupName"])
  ]))
}