
import { defineComponent } from "vue";
import { useI18n } from 'vue-i18n';
import AppUserControls from '@/components/header/AppUserControls.vue';
import AppNavigationList from '@/components/navigation/AppNavigationList.vue'

export default defineComponent({
	name: "AppNavigation",
	components: {
		AppUserControls,
		AppNavigationList
	},
	props: {
		width: {
			type: Number,
			default: 300
		}
	},
	setup() {
		const { t } = useI18n();

		const items = [
			{links: [
				{href: "map", name: t("title.map")}
			]},
			{links: [
				{href: "groups", name: t("title.groups")},
				{href: "enterprises", name: t("title.enterprises")},
				{href: "tags", name: t("title.tags")},
				{href: "resources", name: t("title.resources")}
			]},
			{subtitle: t("dictionary.types"), links: [
				{href: "type_tags", name: t("title.typeTags")},
				{href: "type_resources", name: t("title.typeResources")},
				{href: "type_labels", name: t("title.typeLabels")},
				{href: "kind_type_resources", name: t("title.kindTypeResources")}
			]},
			{subtitle: t("dictionary.pairIsRules"), links: [
				{href: "legal_pairs", name: t("title.legalPairs"), isDeprecated: true },
				{href: "type_links", name: t("title.links")},
				{href: "rules_deprecated", name: t("title.rules"), isDeprecated: true },
				{href: "rules", name: t("title.rules")}
			]},
			{links: [
				{href: "searches", name: t("title.searches")},
			]}
		]

		return { items }
	}
});
