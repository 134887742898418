import {AxiosApiClient} from "@/api/axiosApiClient";
import {Rules} from "@/models";
import {list} from "@/api/list";

export class RuleApi extends AxiosApiClient implements Rules.RuleService {
	private readonly baseUrl = '/rules/';

	public async list(params: Rules.RuleQueryParams = {}, id_enterprise: number): Promise<Rules.RuleList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<Rules.Rule, Rules.RuleQueryParams>(items, params);
	}

	public async add(params: Rules.AddRuleCommand): Promise<Rules.Rule> {
		return await this.post(this.baseUrl, params);
	}

	public async remove(legal_pair_links_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + legal_pair_links_id);
	}

	public async addPair(params: Rules.AddPairRuleCommand): Promise<{id: number}> {
		return await this.post(this.baseUrl + 'create', params);
	}
}