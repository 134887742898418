
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		show: Boolean,
		cssClass: {
			type: String,
			default: ''
		}
	},

	setup() {
		const listeners = {
			beforeEnter: setHeightToNull,
			enter: setFullHeight,
			afterEnter: setFullHeight,
			beforeLeave: setFullHeight,
			leave: setHeightToNull,
			afterLeave: setHeightToNull,
		}

		function setHeightToNull(element: HTMLElement) {
			element.style.maxHeight = '0px'
		}

		function setFullHeight(element: HTMLElement) {
			element.style.maxHeight = `${element.scrollHeight}px`
		}

		return { listeners }
	}
})
