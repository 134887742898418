import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class LegalPairList extends PagedQueryResult<LegalPair> {
	constructor(copy?: Partial<LegalPairList>) {
		super();
		Object.assign(this, copy);
	}
}

export class LegalPairListFull extends PagedQueryResult<LegalPairFull> {
	constructor(copy?: Partial<LegalPairListFull>) {
		super();
		Object.assign(this, copy);
	}
}

export class LegalPairQueryParams extends PagedQueryParams {}

export type Basic = {
	id_type_res1: number
	id_type_res2: number
	master_resource_position: number
	if_friendly: boolean
};

export type LegalPair = Basic & {
	id: number
	id_enterprise: number
};

export type LegalPairFull = Basic & {
    name1: string
    name2: string
    lr_name: string
    rl_name: string
    mandatory: true
	id_enterprise: number
};

export type AddLegalPairCommand = Basic & {
	id_enterprise: number
};

export type EditLegalPairCommand = Basic & {
	id: number
};

export interface LegalPairService {
	list(params: LegalPairQueryParams, id_enterprise: number): Promise<LegalPairList>;
	listFull(params: LegalPairQueryParams, id_enterprise: number): Promise<LegalPairListFull>;
	add(params: AddLegalPairCommand): Promise<LegalPair>;
	edit(params: EditLegalPairCommand): Promise<LegalPair>;
	remove(type_tag_id: number): Promise<null>;
}
