import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class TypeTagList extends PagedQueryResult<TypeTag> {
	constructor(copy?: Partial<TypeTagList>) {
		super();
		Object.assign(this, copy);
	}
}

export class TypeTagQueryParams extends PagedQueryParams {}

export type Basic = {
	name: string
	comment: string
};

export type TypeTag = Basic & {
	id: number
    date_time_start: string
    date_time_end: string
    id_enterprise: number
};

export type AddTypeTagCommand = Basic & {
	id_enterprise: number
};

export type EditTypeTagCommand = Basic & {
	id: number
};

export interface TypeTagService {
	list(params: TypeTagQueryParams, id_enterprise: number): Promise<TypeTagList>;
	add(params: AddTypeTagCommand): Promise<TypeTag>;
	edit(params: EditTypeTagCommand): Promise<TypeTag>;
	remove(type_tag_id: number): Promise<null>;
	block(type_tag_id: number): Promise<null>;
	unblock(type_tag_id: number): Promise<null>;
}
