import { PagedQueryResult, PagedQueryParams } from '@/models/query';

export class GroupList extends PagedQueryResult<Group> {
	constructor(copy?: Partial<GroupList>) {
		super();
		Object.assign(this, copy);
	}
}

export class GroupQueryParams extends PagedQueryParams {}

export type Basic = {
	name: string
	comment: string
};

export type Group = Basic & {
	id: number
};

export type AddGroupCommand = Basic;

export type EditGroupCommand = Group;

export interface GroupService {
	list(params: GroupQueryParams): Promise<GroupList>;
	add(params: AddGroupCommand): Promise<Group>;
	edit(params: EditGroupCommand): Promise<Group>;
	remove(id_group: number): Promise<null>;
}