import {AxiosApiClient} from "@/api/axiosApiClient";
import {Maps} from "@/models";
import {list} from "@/api/list";

export class MapApi extends AxiosApiClient implements Maps.MapService {
	public async maps(params: Maps.MapQueryParams = {}, id_enterprise: number, id_type_res: number): Promise<Maps.MapsList> {
		const items = await this.get(`/get_map/${id_type_res}/${id_enterprise}`);
		return list<Maps.Map, Maps.MapQueryParams>(items, params);
	}

	public async search(id_enterprise: number, name: string, cancelToken: any): Promise<Maps.Map[]> {
		return await this.get(`/get_map/search/${id_enterprise}/${name}`, {}, cancelToken);
	}

	public async linkedResources(params: Maps.MapQueryParams = {}, id_res: number): Promise<Maps.LinkedResourcesList> {
		const items = await this.get(`/get_linked_res/${id_res}`);
		return list<Maps.LinkedResource, Maps.MapQueryParams>(items, params);
	}

	public async history(params: Maps.MapQueryParams = {}, id_res: number): Promise<Maps.LinkedResourcesHistoryList> {
		const items = await this.get(`/get_linked_res/history/${id_res}`);
		return list<Maps.LinkedResourceHistory, Maps.MapQueryParams>(items, params);
	}

	public async linkedFilter(params: Maps.LinkedFilterQueryParams): Promise<Maps.LinkedResourcesList> {
		const items = await this.post(`/get_linked_res/fltr`, params);
		return list<Maps.LinkedResource, Maps.LinkedFilterQueryParams>(items, params);
	}

	public async linkedFilterHistory(params: Maps.LinkedFilterQueryParams): Promise<Maps.LinkedResourcesHistoryList> {
		const items = await this.post(`/get_linked_res/fltr/history`, params);
		return list<Maps.LinkedResourceHistory, Maps.LinkedFilterQueryParams>(items, params);
	}
}