import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_user_controls = _resolveComponent("app-user-controls")!
  const _component_app_navigation_list = _resolveComponent("app-navigation-list")!

  return (_openBlock(), _createElementBlock("div", {
    class: "app-navigation",
    style: _normalizeStyle(`width: ${_ctx.width}px`)
  }, [
    _createVNode(_component_app_user_controls, { class: "laptop-show py-2 px-3" }),
    _createVNode(_component_app_navigation_list, { items: _ctx.items }, null, 8, ["items"])
  ], 4))
}