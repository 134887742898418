import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "app-input__slot" }
const _hoisted_3 = {
  key: 0,
  class: "app-input__prepend-inner"
}
const _hoisted_4 = { class: "app-input__field" }
const _hoisted_5 = ["id", "type", "placeholder", "disabled", "readonly"]
const _hoisted_6 = {
  key: 0,
  class: "error--text"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-input", _ctx.styled])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: "app-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["app-input__control", {disabled: _ctx.disabled}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.iconPrepend)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "iconPrepend")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            id: `input-${_ctx.name}`,
            type: _ctx.type,
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeValue($event, 'update:modelValue'))),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeValue($event, 'blur'))),
            disabled: _ctx.disabled,
            readonly: _ctx.readonly
          }, null, 40, _hoisted_5), [
            [_vModelDynamic, _ctx.innerValue]
          ])
        ]),
        (_ctx.iconAppend)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["app-input__append-inner", { 'app-input__append-inner--action': _ctx.iconAppendAction }]),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.iconAppendAction && _ctx.iconAppendAction(...args)))
            }, [
              _renderSlot(_ctx.$slots, "iconAppend")
            ], 2))
          : _createCommentVNode("", true)
      ]),
      (_ctx.hasSlot("details"))
        ? _renderSlot(_ctx.$slots, "details", { key: 0 })
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.errorMessage || _ctx.hint)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["app-input__details", {"absolute": _ctx.errorMessage && _ctx.isAbsolutePositionDetails}])
                }, [
                  (_ctx.errorMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
                    : _createCommentVNode("", true),
                  (_ctx.hint)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.hint), 1))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
    ], 2)
  ], 2))
}