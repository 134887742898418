import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class ResourceList extends PagedQueryResult<Resource> {
	constructor(copy?: Partial<ResourceList>) {
		super();
		Object.assign(this, copy);
	}
}

export class ResourceQueryParams extends PagedQueryParams {}

export type Basic = {
	id: number
	name: string
	external_id: string
	external_id_type: string
	description: {[key: string]: any}
	id_type_res: number
	personal_avatar: string
	id_enterprise: number
};

export type Resource = Basic & {
	id_tag: number
    date_time_start: string
    date_time_end: string
};

export type AddResourceCommand = Basic & {
	id_tag?: number
};

export type EditResourceCommand = Basic & {
	id_tag?: number
};

export interface ResourceService {
	list(params: ResourceQueryParams, id_enterprise: number): Promise<ResourceList>;
	listByType(params: ResourceQueryParams, id_enterprise: number, id_type_res: number): Promise<ResourceList>;
	listByLabel(params: ResourceQueryParams, label: string): Promise<ResourceList>;
	add(params: AddResourceCommand): Promise<Resource>;
	edit(params: EditResourceCommand): Promise<Resource>;
	remove(id_res: number): Promise<null>;
	block(id_res: number): Promise<null>;
	unblock(id_res: number): Promise<null>;
}
