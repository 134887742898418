import {AxiosApiClient} from "@/api/axiosApiClient";
import {Pairs} from "@/models";

export class PairApi extends AxiosApiClient implements Pairs.PairService {
	private readonly baseUrl = '/pair/';

	public async add(params: Pairs.AddPairCommand): Promise<{id: number}> {
		return await this.post(this.baseUrl + 'create', params);
	}

	public async addOne(params: Pairs.AddPairCommand): Promise<{id: number}> {
		return await this.post(this.baseUrl + 'one/create', params);
	}

	public async block(id_pair: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + id_pair);
	}

	public async unblock(id_pair: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + id_pair);
	}

	public async fixed(id_pair: number): Promise<null> {
		return await this.put(this.baseUrl + 'fixed/' + id_pair);
	}

	public async unfixed(id_pair: number): Promise<null> {
		return await this.put(this.baseUrl + 'unfixed/' + id_pair);
	}
}