import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_navigation = _resolveComponent("app-navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_snackbar = _resolveComponent("app-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_header, {
      onMenuChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMenuOpened = $event))
    }),
    _createVNode(_component_app_navigation, {
      width: _ctx.navigationWidth,
      class: _normalizeClass(_ctx.isMenuOpened ? "active" : "hide")
    }, null, 8, ["width", "class"]),
    _createElementVNode("div", {
      class: _normalizeClass(["app-wrapper", {"blur": _ctx.isMenuOpened}]),
      style: _normalizeStyle(`padding-left: ${_ctx.isLaptop ? _ctx.navigationWidth : 0}px; padding-right: ${_ctx.isNavigationRight}px`)
    }, [
      _createVNode(_component_router_view)
    ], 6),
    _createVNode(_component_app_snackbar, {
      notification: _ctx.state.notification,
      onCloseSnackbar: _cache[1] || (_cache[1] = ($event: any) => (_ctx.commit('notification/clearMessage')))
    }, null, 8, ["notification"])
  ], 64))
}