
import { defineComponent, PropType, ref, computed, watch, ComponentPublicInstance } from 'vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import groupBy from 'lodash/groupBy'

type SelectRecord = {
	id: number
	name?: string
	value?: string | number
	[key: string]: any
}

export default defineComponent({
	name: 'AppSelect',
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: false
		},
		label: {
			type: String
		},
		items: {
			type: Array as PropType<SelectRecord[]>,
			required: true
		},
		selected: {
			type: [ Object, Array ] as PropType<SelectRecord | any[]>,
			default: null
		},
		multiple: {
			type: Boolean,
			default: false
		},
		multipleLimit: {
			type: Number,
			default: null
		},
		itemName: {
			type: String,
			default: 'name'
		},
		isSubGroup: {
			type: Boolean,
			default: false
		},
		isSubGroupName: {
			type: String,
			default: ""
		},
		isObjectReturn: {
			type: Boolean,
			default: true
		}
	},

	emits: ['selectItem', 'clearSelection'],

	components: {
		PerfectScrollbar
	},

	setup(props, { emit, slots } ) {
		// visual behavior
		const showOptions = ref(false)
		function switchOptions() {
			showOptions.value = !showOptions.value
		}
		function openOptions() {
			showOptions.value = true
		}
		function closeOptions() {
			showOptions.value = false
		}
		const isOptionsListShown = computed(() => {
			return Boolean(showOptions.value && !props.disabled && props.items.length)
		})
		const isClearButtonShown = computed(() => {
			return props.clearable && (typeof props.selected === 'object' ? props.selected?.length > 0 : !!props.selected)
		})
		const currentLabel = computed(() => {
			const concatItemNames = (items: SelectRecord[]) => items.reverse()
				.reduce((label: string, item: SelectRecord) => `${label}, ${item[props.itemName] ?? ''}`, '')
				.slice(2)

			return concatItemNames([ ...selectedItems.value ]) || props.label || null
		})

		// selected
		const selectedItems = computed(() => {
			return props.isObjectReturn
				? ensureArray(props.selected)
				: Array.isArray(props.selected)
					? props.selected.map(id => props.items.find(item => item.id === id))
					: []
		})
		function ensureArray(data: any) {
			return Array.isArray(data) ? [...data] : data ? [data] : [];
		}

		const selectedItemsIds = computed(() => {
			return selectedItems.value.map((item: SelectRecord) => item.id)
		})

		function isItemSelected(id: number): boolean {
			if(!id) return false;
			return selectedItemsIds.value.includes(id);
		}

		function selectItem(item: SelectRecord): void {
			emit('selectItem', buildSelected(item))
			if (!props.multiple && showOptions.value) closeOptions()
		}
		function clearSelection(): void {
			if (showOptions.value) closeOptions()
			emit('clearSelection')
		}

		// auto scroll to selected
		const scrollWrapper = ref<ComponentPublicInstance>()
		watch(scrollWrapper, () => {
			if (!scrollWrapper.value || !props.selected) return;

			const scroller = scrollWrapper.value.$el

			const index = props.items.findIndex(item => selectedItemsIds.value.includes(item.id))
			scroller.scrollTop = index * scroller.querySelector('.item').offsetHeight
		})

		// build returned selected value
		function buildSelected(item: SelectRecord) {
			// return the only element selected
			if (!props.multiple) return item;

			// handle multiple selection
			let selected = [ ...selectedItemsIds.value ]

				// add\exclude selected item from selected array
			selected = selected.indexOf(item.id) === -1
				? [ item.id, ...selected ]
				: selected.filter(id => id !== item.id)

				// handle multipleLimit option
			if (props.multipleLimit && selected.length > props.multipleLimit)
				selected = selected.slice(0, props.multipleLimit)

				// build final version
			if (!selected.length) return null;

			return props.isObjectReturn ? selected.map(id => props.items.find(item => item.id === id)) : selected
		}

		// clear selection if null-prop is passed
		watch(() => props.selected, () => { if (!props.selected) clearSelection() })

		function hasSlot(name = 'default') {
			return !!slots[name];
		}

		return {
			groupBy,
			showOptions, switchOptions, openOptions, closeOptions, isClearButtonShown, isOptionsListShown,
			isItemSelected, selectItem, clearSelection, scrollWrapper,
			currentLabel,
			hasSlot
		}
	}
})
