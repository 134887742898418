import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_fade_transition = _resolveComponent("app-fade-transition")!
  const _component_app_click_away = _resolveComponent("app-click-away")!

  return (_openBlock(), _createBlock(_component_app_click_away, { onClickedAway: _ctx.close }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["app-floating-wrapper", { opened: _ctx.opened, disabled: _ctx.disabled }]),
        ref: "wrapper"
      }, [
        _createElementVNode("div", {
          class: "app-floating-wrapper-activator",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
        }, [
          _renderSlot(_ctx.$slots, "activator")
        ]),
        _createVNode(_component_app_fade_transition, { show: _ctx.opened }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["app-floating-wrapper-card", { opened: _ctx.opened, top: (_ctx.top && !_ctx.bottom) || _ctx.moveToTop, bottom: (_ctx.bottom && !_ctx.top) || !_ctx.moveToTop, left: _ctx.left, right: _ctx.right }]),
              ref: "popup"
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2)
          ]),
          _: 3
        }, 8, ["show"])
      ], 2)
    ]),
    _: 3
  }, 8, ["onClickedAway"]))
}