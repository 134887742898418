import { PagedQueryParams } from "@/models/query";
import sortBy from 'lodash/sortBy';

export function list<T, P extends PagedQueryParams>(items: T[], params: P) {
	const total = items?.length ?? 0;
	const size = params.pageSize ?? 5;
	const current = params.pageNumber ?? 1;
	const sorting = params.sortCriterion
		? params.sortDirection === 0
			? sortBy(items, params.sortCriterion)
			: sortBy(items, params.sortCriterion).reverse()
		: items;

	return {
		total,
		size,
		current,
		last: Math.ceil(total / size),
		items: sorting.slice((current - 1) * size, current * size)
	};
}