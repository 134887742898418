
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

export default defineComponent({
	name: 'AppClickAway',

	emits: ['clicked-away'],

	setup(_, { emit }) {

		const el = ref(null as HTMLElement | null)

		function handleOutsideClick(event: any) {
			event.stopPropagation()
			if (!el.value || !el.value.contains(event.target))
				emit('clicked-away')
		}

		onMounted(() => {
			document.addEventListener('click', handleOutsideClick, false)
			document.addEventListener('touchstart', handleOutsideClick, false)
		})

		onBeforeUnmount(() => {
			document.removeEventListener('click', handleOutsideClick, false)
			document.removeEventListener('touchstart', handleOutsideClick, false)
		})

		return { el }
	}
})
