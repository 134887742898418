export default {
	groups: "Groups",
	enterprises: "Enterprises",
	typeTags: "Type tags",
	typeResources: "Type resources",
	typeLabels: "Type labels",
	kindTypeResources: "Kind type resources",
	legalPairs: "Legal pairs",
	rules: "Rules",
	links: "Links",
	changeEnterprise: "Change enterprise",
	pageNotFound: "Page not found",
	tags: "Tags",
	labels: "Labels",
	resources: "Resources",
	pair: "Pair",
	pairs: "Pairs",
	map: "Map",
	searches: "Searches",
}