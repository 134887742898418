import {AxiosApiClient} from "@/api/axiosApiClient";
import {KindTypeResources} from "@/models";
import {list} from "@/api/list";

export class KindTypeResourceApi extends AxiosApiClient implements KindTypeResources.KindTypeResourceService {
	private readonly baseUrl = '/kind_type_res/';

	public async list(params: KindTypeResources.KindTypeResourceQueryParams = {}, id_enterprise: number): Promise<KindTypeResources.KindTypeResourceList> {
		const items = await this.get(this.baseUrl + id_enterprise);
		return list<KindTypeResources.KindTypeResource, KindTypeResources.KindTypeResourceQueryParams>(items, params);
	}

	public async add(params: KindTypeResources.AddKindTypeResourceCommand): Promise<KindTypeResources.KindTypeResource> {
		return await this.post(this.baseUrl, params);
	}

	public async edit(params: KindTypeResources.EditKindTypeResourceCommand): Promise<KindTypeResources.KindTypeResource> {
		return await this.put(this.baseUrl, params);
	}

	public async remove(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + in_id);
	}

	public async block(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'block/' + in_id);
	}

	public async unblock(in_id: number): Promise<null> {
		return await this.deleteRequest(this.baseUrl + 'unblock/' + in_id);
	}
}