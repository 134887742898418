
import { defineComponent, ref, computed, PropType, watch, onMounted } from 'vue'

type SelectRecord = {
	id: number
	name?: string
	value?: string | number
	[key: string]: any
}

const SearchTextMinLength = 1

export default defineComponent({
	name: 'AppAutocomplete',
	emits: ['item-selected', 'selection-cleared', 'input'],
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: false
		},
		label: {
			type: String
		},
		items: {
			type: Array as PropType<SelectRecord[]>,
			required: true
		},
		selected: {
			type: Object as PropType<SelectRecord>,
			default: null
		},
		iconPrepend: {
			type: Boolean,
			default: false
		},
		iconAppend: {
			type: Boolean,
			default: true
		},
		isRequest: {
			type: Boolean,
			default: false
		},
		itemName: {
			type: String,
			default: 'name'
		},
		initSearchText: {
			type: String,
			default: ""
		},
		isSubGroup: {
			type: Boolean,
			default: false
		},
		isSubGroupName: {
			type: String,
			default: ""
		}
	},
	setup(props, { emit, slots }) {
		const inputId = String(new Date().getTime() + Math.random())

		const searchText = ref('')

		const placeholder = computed(() => props.label || '---')

		const filteredItems = computed(() => {
			if(!props.isRequest) {
				if (searchText.value.length <= SearchTextMinLength
					|| searchText.value === props.selected?.[props.itemName]) return props.items;

				const re = new RegExp(searchText.value, 'ig')
				return props.items.filter(item => item[props.itemName]?.match(re))
			} else {
				return props.items;
			}
		})

		onMounted(() => {
			setSearchText()
		})
		watch(() => props.selected, () => {
			setSearchText()
		})
		watch(() => props.initSearchText, () => {
			searchText.value = props.initSearchText;
		})
		function setSearchText() {
			if (props.selected && searchText.value !== props.selected[props.itemName]) {
				searchText.value = props.selected[props.itemName] ?? ""
			} else {
				searchText.value = ''
			}
		}

		watch(() => searchText.value, () => {
			if (props.selected && searchText.value !== props.selected[props.itemName]) clearSelection()
		})

		function selectItem(item: SelectRecord) {
			emit('item-selected', item)
		}


		const isClearButtonShown = computed(() => props.clearable && !!props.selected)

		function clearSelection() {
			searchText.value = '';
			emit('selection-cleared');
		}

		function hasSlot(name = 'default') {
			return !!slots[name];
		}

		return { inputId, searchText, placeholder, selectItem, filteredItems, isClearButtonShown, clearSelection, hasSlot }
	}
})
