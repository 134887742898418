
import { defineComponent, computed } from 'vue'
import { constants } from '@/helpers/constants'

const maxPagesRendered = 3
const pageBreakId = constants.limitlessPageSize

export default defineComponent({
	name: 'AppPagination',
	props: {
		total: {
			type: Number,
			required: true
		},
		pageNumber: {
			type: Number,
			required: true,
		},
		pageSize: {
			type: Number,
			required: true
		}
	},
	emits: ['page-changed'],

	setup(props, { emit }) {
		const pageCount = computed(() => Math.ceil(props.total / props.pageSize))

        const renderedPages = computed(() => {
            if (pageCount.value <= maxPagesRendered) {
                return new Array(pageCount.value).fill(0).map((_, index) => index + 1)
            }

            const maxPagesBeforeCurrentPage = Math.floor(maxPagesRendered / 2)
            const maxPagesAfterCurrentPage = Math.ceil(maxPagesRendered / 2) - 1

            let startPage: number, endPage: number
            if (props.pageNumber <= maxPagesBeforeCurrentPage) {
                startPage = 1
                endPage = maxPagesRendered
            } else if (props.pageNumber + maxPagesAfterCurrentPage >= pageCount.value) {
                startPage = pageCount.value - maxPagesRendered + 1
                endPage = pageCount.value
            } else {
                startPage = props.pageNumber - maxPagesBeforeCurrentPage
                endPage = props.pageNumber + maxPagesAfterCurrentPage
            }

            const middle = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i)

            const start = startPage === 1
                ? [] : startPage === 2
                    ? [ 1 ] : [ 1, pageBreakId ]

            const end = endPage === pageCount.value
                ? [] : endPage === pageCount.value - 1
                    ? [ pageCount.value ] : [ pageBreakId, pageCount.value ]

            return [ ...start, ...middle, ...end ]
        })

		function changePage(page: number) {
			emit('page-changed', page)
		}

		function changePageToPrev() {
			emit('page-changed', props.pageNumber - 1)
		}
		function changePageToNext() {
			emit('page-changed', props.pageNumber + 1)
		}

		const nextPageItemCount = computed(() => props.pageNumber === pageCount.value - 1
				? props.total % props.pageSize
				: props.pageSize
		)

		return { pageCount, renderedPages, pageBreakId,
			changePage, changePageToPrev, changePageToNext, nextPageItemCount }
	}
})
