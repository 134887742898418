
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'AppSpinner',
	props: {
		loading: Boolean,
		cover: Boolean,
		absolute: Boolean,
		wide: Boolean,
		small: Boolean
	},

	setup(props) {
		const classes = computed(() => ({
			'cover': props.cover,
			'absolute': props.absolute,
			'wide': props.wide,
			'small': props.small
		}))

		return { classes }
	}
})
