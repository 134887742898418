import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-header" }
const _hoisted_2 = { class: "container container-fluid d-flex align-center" }
const _hoisted_3 = { class: "ml-auto" }
const _hoisted_4 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_logo = _resolveComponent("app-logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_user_controls = _resolveComponent("app-user-controls")!
  const _component_app_menu = _resolveComponent("app-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_logo),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.state.globals.enterpriseId)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {name: 'change_enterprise'},
                class: "text-body-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("title.changeEnterprise")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_app_user_controls, { class: "laptop-hide ml-6" }),
          _createVNode(_component_app_menu, {
            class: "laptop-show ml-6",
            onMenuChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("menu-changed", $event)))
          })
        ])
      ])
    ])
  ]))
}