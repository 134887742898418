import * as Api from '@/api';
import * as Models from "@/models";
import {ActionContext} from "vuex";
import {lcFirst} from '@/helpers/firstRegister';
import { constants } from '@/helpers/constants';

export interface S {
	[key: string]: any
}

export interface R {
	globals: {
		enterpriseId: number
	}
}

const state = {
	...Object.keys(Models).reduce((next, model) => ({ ...next, [lcFirst(model)]: [] }), {}),
	tagsFree: [],
	maps: [],
	linkedResources: [],
	histories: []
};

const mutations = {
	...Object.keys(Models).reduce((next, model) => ({ ...next,
		[`set${model}`](state: S, payload: {items: {[key: string]: any}[]}) {
			state[lcFirst(model)] = payload.items
		}
	}), {}),
	setTagsFree(state: S, payload: {items: {[key: string]: any}[]}) {
		state.tagsFree = payload.items
	},
	setMaps(state: S, payload: {items: {[key: string]: any}[]}) {
		state.maps = payload.items
	},
	setLinkedResources(state: S, payload: {items: {[key: string]: any}[]}) {
		state.linkedResources = payload.items
	},
	setHistory(state: S, payload: {items: {[key: string]: any}[]}) {
		state.histories = payload.items
	}
};

const DictionaryQueryParams = {pageSize: constants.limitlessPageSize, pageNumber: 1};

const includes = ['Pairs', 'Maps', 'Searches'];

const actions = {
	...Object.keys(Models).filter(model => !includes.includes(model)).reduce((next, model: string) => ({ ...next,
		async [`get${model}`](context: ActionContext<S, R>) {
			const api: S = Api;
			switch (model) {
				case 'Groups': {
					context.commit(`set${model}`, await new api[`${model}`][`${model.slice(0, -1)}Api`]().list(DictionaryQueryParams));
					break;
				}
				default: {
					context.commit(`set${model}`, await new api[`${model}`][`${model.slice(0, -1)}Api`]().list(DictionaryQueryParams, context.rootState.globals.enterpriseId));
					break;
				}
			}
		},
	}), {}),
	async getTagsFree(context: ActionContext<S, R>) {
		context.commit('setTagsFree', await new Api.Tags.TagApi().listFree(DictionaryQueryParams, context.rootState.globals.enterpriseId));
	},
	async getMaps(context: ActionContext<S, R>, payload: any) {
		context.commit(`setMaps`, await new Api.Maps.MapApi().maps(DictionaryQueryParams, context.rootState.globals.enterpriseId, payload));
	},
	async getLinkedResources(context: ActionContext<S, R>, payload: any) {
		context.commit(`setLinkedResources`, await new Api.Maps.MapApi().linkedFilter({...DictionaryQueryParams, ...payload}));
	},
	async getHistory(context: ActionContext<S, R>, payload: any) {
		context.commit(`setHistory`, await new Api.Maps.MapApi().linkedFilterHistory({...DictionaryQueryParams, ...payload}));
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}