export default {
	addName: "Add a name",
	addComment: "Add a comment",
	addAddress: "Add an address",
	addDescription: "Add a description",
	addLabel: "Add a label",
	addExternalId: "Add external id",
	addExternalIdType: "Add external id type",
	addLrName: "Add left name",
	addRlName: "Add right name",
	addPriority: "Add priority",
	addUniqForRes: "Add uniq for resources",
	addMasterResourcePosition: "Add master resource position",
	editName: "Edit a name",
	editComment: "Edit a comment",
	editAddress: "Edit an address",
	editLabel: "Edit a label",
	editExternalId: "Edit external id",
	editExternalIdType: "Edit external id type",
	editLrName: "Edit left name",
	editRlName: "Edit right name",
	editPriority: "Edit priority",
	editUniqForRes: "Edit uniq for resources",
	editMasterResourcePosition: "Edit master resource position",
	findEnterprise: "Find enterprise",
	findTag: "Find tag"
}