import { PagedQueryResult, PagedQueryParams } from "@/models/query";

export class TypeResourceList extends PagedQueryResult<TypeResource> {
	constructor(copy?: Partial<TypeResourceList>) {
		super();
		Object.assign(this, copy);
	}
}

export class TypeResourceQueryParams extends PagedQueryParams {}

export type Basic = {
	name: string
	comment: string
	avatar: string
	description_pattern: {[key: string]: any}
};

export type TypeResource = Basic & {
	id: number
    date_time_start: string
    date_time_end: string
    id_enterprise: number
};

export type AddTypeResourceCommand = Basic & {
	id_enterprise: number
};

export type EditTypeResourceCommand = Basic & {
	id: number
};

export interface TypeResourceService {
	list(params: TypeResourceQueryParams, id_enterprise: number): Promise<TypeResourceList>;
	add(params: AddTypeResourceCommand): Promise<TypeResource>;
	edit(params: EditTypeResourceCommand): Promise<TypeResource>;
	remove(type_res_id: number): Promise<null>;
	block(type_res_id: number): Promise<null>;
	unblock(type_res_id: number): Promise<null>;
}
