
import {defineComponent} from 'vue';

export default defineComponent({
	emits: ["dialog-closed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		width: {
			type: Number,
			default: 890
		},
		classContent: {
			type: String
		}
	},
	setup(_, {emit}) {
		const closeDialog = () => emit('dialog-closed');

		return { closeDialog }
	}
})
