
	import { defineComponent, computed } from 'vue'

	export default defineComponent({
		name: "AppButton",
		emits: ['click'],
		props: {
			to: { required: false },
			outlined: Boolean,
			block: Boolean,
			disabled: Boolean,
			loading: Boolean,
			large: Boolean,
			small: Boolean,
			xsmall: Boolean,
			icon: Boolean,
			color: String,
			radius: {
				type: Number,
				default: 0
			}
		},
		setup(props) {
			const type = computed(() => props.to ? "router-link" : "button");
			const classes = computed(() => {
				return {
					"app-btn": true,
					"app-btn--outlined": props.outlined,
					"app-btn--block": props.block,
					"app-btn--disabled": props.disabled,
					"app-btn--loading": props.loading,
					"app-btn--icon": props.icon,
					"app-btn--large": props.large,
					"app-btn--small": props.small,
					"app-btn--xsmall": props.xsmall,
					[props.color && props.outlined ? props.color + "--text" : props.color as string]: props.color
				};
			})

			return { type, classes }
		}
	})
